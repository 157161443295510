// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// About page

import { Container, Row, Col } from 'react-bootstrap';
import Header from '../components/Header.js';
import Card from '../components/Card.js';
import Button from '../components/Button.js';
import Grid from '../components/Grid.js';
import Handshake from '../assets/images/handshake.svg';
import LowCost from '../assets/images/low-cost.svg';
import Headshot from '../assets/images/headshot.png';
import MapBackground from '../assets/images/map-background-1.svg';
import HeaderImage from '../assets/images/about-header.jpg';
import styled from 'styled-components';

const About = () => {
  return (
    <>
      <Header text="Creating trust" image={HeaderImage} page="services" />
      <Container>
        <StyledRow>
          <h1>
            We will exceed your expectations at the most affordable price.
          </h1>
        </StyledRow>
        <StyledRow>
          <StyledCol sm={6}>
            <StyledImage src={Handshake} alt="handshake" />
          </StyledCol>
          <StyledCol sm={6}>
            <Card title="Tust" image={null}
              paragraph={"Full handyman service - We do it all. Creating long lasting relationships. Employ only experienced and qualified professionals. Servicing Wilmington, NC and surrounding areas."} />
            <Button text="Learn More" path="/contact" type="main" />
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol sm={6}>
            <StyledImage src={LowCost} alt="low cost" />
          </StyledCol>
          <StyledCol sm={6}>
            <Card title="Save" image={null}
              paragraph={"Save money on expensive specialists. We work efficiently and take care of all your needs. Providing you the highest quality handyman service available. Getting the job done right the first time. The most affordable price."} />
            <Button text="Learn More" path="/services" type="main" />
          </StyledCol>
        </StyledRow>
      </Container>
      <Container style={{ background: '#b8b8b8' }} fluid>
        <Container>
          <StyledRow>
            <StyledCol sm={8}>
              <StyledText style={{ borderBottom: '2px #a6001d solid' }}>Meet the owner</StyledText>
              <p>
                Logan Furlough is a North Carolina native through and through. As a Wilmington resident, Logan enjoys spending time at the beach or catching a
                live show around town. Southern hospitality is prevalent in how Logan conducts his business, with going
                above and beyond to make sure the customer is satisfied with the completed job and with
                guaranteeing his work with a handshake. Logan will do what it takes to earn your business and to make sure the job gets done right the first time.
              </p>
            </StyledCol>
            <StyledCol sm={4}>
              <HeadshotImage src={Headshot} />
            </StyledCol>
          </StyledRow>
        </Container>
      </Container>
      <Container fluid>
        <StyledRowBackground>
          <Container>
            <StyledText>Testimonials</StyledText>
            <Grid />
          </Container>
        </StyledRowBackground>
      </Container>
    </>
  );
}

const StyledCol = styled(Col)`
    margin-bottom: 3.125rem;
    @media (max-width: 576px) {
        margin-bottom: 6.25rem;
    }
`;

const StyledRow = styled(Row)`
    padding: 3.75rem 0rem 3.75rem 0rem;
`;

const StyledText = styled.h1`
    font-family: 'Nexa Regular';  
    font-size: 4rem;
    margin-bottom: 2.5rem;
    @media (max-width: 576px) {
        font-size: 2.143rem;
    }
`;

const StyledImage = styled.img`
    width: 25rem;
    border-radius: 50%;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.2);
    @media (max-width: 1000px) {
        width: 17rem;
    }
`;

const StyledRowBackground = styled(Row)`
    padding: 3.75rem 3.75rem 3.75rem 3.75rem;
    text-align: center;
    color: #fff;
    background-image: url(${MapBackground});
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const HeadshotImage = styled.img`
    width: 25rem;
    border-radius: 50%;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.2);
    @media (max-width: 1000px) {
        width: 17rem;
    }
`;

export default About;
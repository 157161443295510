// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// Navbar

import { Navbar, Nav, Container } from 'react-bootstrap';
import scrollPosition from './ScrollPosition.js';
import styled from 'styled-components';

const CustomNavbar = () => {
  const y = scrollPosition();
  const styles = {
    top: {
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
    scrolled: {
      backgroundColor: 'rgba(166, 0, 29, 1)',
      fontSize: 18,
    }
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" fixed="top" style={y.scrollY > 100 ? styles.scrolled : styles.top}>
        <Container>
          <StyledBrand>
            <StyledText>Cardinal Pride<a style={{ fontSize: 10 }}>  LLC.</a></StyledText>
          </StyledBrand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <StyledCollapse id="basic-navbar-nav">
            <StyledNav className="ms-auto">
              <StyledNavLink href="/">Home</StyledNavLink>
              <StyledNavLink href="/services">Services</StyledNavLink>
              <StyledNavLink href="/about">About</StyledNavLink>
              <StyledNavLink href="/contact">Contact</StyledNavLink>
            </StyledNav>
          </StyledCollapse>
        </Container>
      </Navbar>
    </>
  );
};

const StyledBrand = styled(Navbar.Brand)`
    margin-left: 12rem;
    @media (max-width: 1200px) {
        margin-left: 1rem;
    }
`;

const StyledCollapse = styled(Navbar.Collapse)`
    background-color: none;
`;

const StyledNav = styled(Nav)`
    margin-right: 12rem;
    @media (max-width: 1200px) {
        margin-right: 1rem;
    }
`;

const StyledNavLink = styled(Nav.Link)`
    color: #ffffff !important;
    font-size: 16px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    &:hover {
        color: #CAB686 !important;
    }
`;

const StyledText = styled.h2`
    font-family: 'Nexa Regular';  
    color: #ffffff;
    font-size: 1.7rem;
    padding-top: 0.75rem;
`;

export default CustomNavbar;
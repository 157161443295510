// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// Button

import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Button = ({ text, path, type, ...buttonProps }) => {
  if (type === 'main') {
    let color = '#000000';
    let background = 'rgba(204, 121, 136, 0)';
    let background2 = 'rgba(204, 121, 136, 1)';
    return (
      <div {...buttonProps}>
        <StyledButton href={path} color={color} background={background} background2={background2}>{text}</StyledButton>
      </div>
    )
  }

  if (type === 'secondary') {
    let color = '#ffffff';
    let background = 'rgba(166, 0, 29, 0.75)';
    let background2 = 'rgba(166, 0, 29, 1)';
    return (
      <div {...buttonProps}>
        <StyledButton href={path} color={color} background={background} background2={background2}>{text}</StyledButton>
      </div>
    )
  }

}

const StyledButton = styled.a`
    position: relative;
    display: block;
    margin: 1.875rem auto 0;
    padding: 0.9rem;
    color: ${(props) => props.color};
    background: ${(props) => props.background};
    font-size: 0.875rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.08em;
    border-radius: 5px;
    width: 15rem;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.2);
    border: 2px solid ${(props) => props.background2};
    &:hover {
        color: ${(props) => props.color};
        background: ${(props) => props.background2};
    }
`;

export default Button;

// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// Custom modal using bootstrap modal

import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
let data = [
  ['Rot repair', 'Water damage repair', 'Soffits and fascia repair', 'Gutter cleaning and repair', 'Siding repair', 'Roof and flashing repair', 'Deck repair', 'Fence repair', 'Pressure washing', 'Windows hung or repaired', 'Doors hung or repaired', 'Lock sets'],
  ['Trim carpentry', 'Trim carpentry', 'Drywall / sheetrock repair', 'Crown and base molding', 'Chair rails, wainscotting', 'Columns installed', 'Doors hung or repaired', 'Door knobs and hinges replaced', 'Windows installed or repaired', 'Weather stripping', 'Ceiling fans installed', 'Light fixtures installed', 'Switches replaced', 'Air filters replaced', 'Water filters replaced', 'Add or remove shelving', 'Closet doors hung or adjusted', 'Closet organizers installed', 'Hand rails repaired or installed', 'Blinds installed', 'Curtain rods installed', 'Mirror hanging', 'Picture hanging'],
  ['Wall tile installed', 'Floor tile installed', 'Grout repair', 'Caulking', 'Shower doors installed', 'Faucets installed', 'Bath accessories hung', 'Disposals installed', 'Appliances installed', 'Counter tops installed', 'Backs plashes installed', 'Cabinets installed', 'Hardware replaced', 'Vent fans installed', 'Toilets installed', 'Sinks installed', 'Tubs and showers installed'],
  ['Painting', 'Masonry', 'Doorbells installed', 'Pet doors installed', 'Mailboxes installed', 'Doghouses installed', 'Bird houses installed', 'Swing sets assembled', 'Storage sheds assembled', 'Furniture assembled', 'Home sale prep', 'Inspection repairs', 'Furniture and box moving', 'Senior and infant safety installs', 'Yard work', 'Debris removal', 'Troubleshooting']
];

const Popup = ({ state, handleClose, selection, index }) => {
  return (
    <>
      <StyledModal centered={true} show={state} onHide={handleClose}>
        <StyledHeader className="border-0" closeButton>
          <StyledTitle>{selection}</StyledTitle>
        </StyledHeader>
        <Modal.Body>
          {data[index].map((item, i) => {
            return (
              <StyledItem key={i}>{data[index][i]}</StyledItem>
            )
          })}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </StyledModal>
    </>
  )
}

const StyledModal = styled(Modal)`
    background-color: rgb(202,182,134);
    background-color: rgba(202,182,134,.7);
`;

const StyledHeader = styled(Modal.Header)`
    margin-left: 25%;
    @media (max-width: 576px) {
      margin-left: 2%;
    }
`;

const StyledTitle = styled(Modal.Title)`
    border-bottom: 2px #a6001d solid;
    font-family: 'Nexa Regular';
    font-size: 3rem;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.2);
`;

const StyledItem = styled.li`
    text-align: left;
    text-decoration: none;
    margin-left: 25%;
    @media (max-width: 576px) {
      margin-left: 2%;
    }
`;


export default Popup;

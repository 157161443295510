// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// App entry point, handles global styles, routing between pages, and global animations

import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar.js';
import Footer from './components/Footer.js';
import Loader from './components/Loader.js';
import Home from './pages/Home.js';
import Services from './pages/Services.js';
import About from './pages/About.js';
import Contact from './pages/Contact.js';
import NexaWoff from './assets/fonts/fontfabric_-_nexa_script-webfont.woff';
import NexaWoff2 from './assets/fonts/fontfabric_-_nexa_script-webfont.woff2';
import { createGlobalStyle } from 'styled-components';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1500);
  }, []);

  return (
    <>
      {loading === false ? (
        <>
          <GlobalStyle />
          <Router>
            <Navbar />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/services' element={<Services />} />
              <Route path='/about' element={<About />} />
              <Route path='/contact' element={<Contact />} />
            </Routes>
            <Footer />
          </Router>
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Lato', sans-serif;
    text-align:center;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  body {
    overflow-x: hidden !important;
  }
  
  .modal-open .navbar {
	padding-left: 16px !important;
  }
    .modal-open {
    overflow: auto !important;
    padding: 0 !important;
  }

  @font-face {
	  font-family: 'Nexa Regular';
	  src: url(${NexaWoff2}) format('woff2'),
		url(${NexaWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  h1 {
	  font-size: 2.857rem;
	  font-weight: bold;
	  padding: 2rem;
	  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.2);
	  @media (max-width: 1000px) {
      font-size: 2.143rem;
	}
  }

  p {
	font-weight: normal;
	font-size: 1.286rem;
	@media (max-width: 1000px) {
	  font-size: 0.857rem;
  }
}

  p2 {
	  font-weight: normal;
	  font-size: 0.857rem
	  @media (max-width: 1000px) {
      font-size: 0.714rem;
	  }
  }
`;

export default App;

// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// Sprite sheet animation

import SpritePage from '../assets/images/loader-sprite.png';
import styled, { keyframes } from 'styled-components';

const Loader = () => {
  return (
    <>
      <StyledDiv style={{isplay: "none" }}>
        <Animation />
      </StyledDiv>
    </>
  )
}
const animation = keyframes`
    100% { background-position: -1000px; }
`;

const StyledDiv = styled.div`
    width: 100vw;
    height: 100vh;
    background: #fff;
    position: fixed; 
    top: 0; 
    left: 0; 
    min-width: 100%;
    min-height: 100%;
`;

const Animation = styled.div`
    height: 100px;
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url(${SpritePage}) left center;
    animation: ${animation} .4s steps(10) infinite; 
`;


export default Loader;
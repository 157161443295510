// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// Services page 

import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../components/Header.js';
import Card from '../components/Card.js';
import Button from '../components/Button.js';
import MapBackground from '../assets/images/map-background-2.svg';
import HeaderImage from '../assets/images/services-header.jpg';
import Exterior from '../assets/images/exterior.svg';
import Interior from '../assets/images/interior.svg';
import KitchenBath from '../assets/images/kitchen-bath.svg';
import OddJobs from '../assets/images/odd-jobs.svg';
import styled from 'styled-components';
import Slides from '../components/Slides.js';
import { motion } from 'framer-motion';

import Popup from '../components/Popup.js';

function Services() {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const [selection, setSelection] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (selection, index) => {
    setShow(true);
    setSelection(selection);
    setIndex(index);
  };

  return (
    <>
      <Popup state={show} handleClose={handleClose} selection={selection} index={index} />
      <Header text="We do it all" image={HeaderImage} page="services" />
      <Container>
        <StyledRow>
          <h1>
            We are ready to discuss your project.
            Ensuring a quality job every time.
          </h1>
          <StyledP>
            Below are partial lists of services we offer.  If you do not see a particular server you need,
            please contact us and we will do our best to assist you.
            Our experienced staff is ready to get the job done right the first time.
          </StyledP>
        </StyledRow>
        <StyledRow>
          <StyledCol sm={6}>
            <Card title={null} image={Exterior}
              paragraph={null} />
            <Button onClick={() => handleShow('Exterior', 0)} text="Exterior" path={null} type="main" />
          </StyledCol>
          <StyledCol sm={6}>
            <Card title={null} image={Interior}
              paragraph={null} />
            <Button onClick={() => handleShow('Interior', 1)} text="Interior" path={null} type="main" />
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol sm={6}>
            <Card title={null} image={KitchenBath}
              paragraph={null} />
            <Button onClick={() => handleShow('KitchenBath', 2)} text="Kitchen Bath" path={null} type="main" />
          </StyledCol>
          <StyledCol sm={6}>
            <Card title={null} image={OddJobs}
              paragraph={null} />
            <Button onClick={() => handleShow('OddJobs', 3)} text="Odd Jobs" path={null} type="main" />
          </StyledCol>
        </StyledRow>
      </Container>
      <Container fluid>
        <StyledRowBackground>
          <StyledText>
            Recent work
          </StyledText>
          <Slides />
        </StyledRowBackground>
      </Container>
    </>
  );
}

const StyledP = styled.p`
  @media (max-width: 576px) {
    margin-bottom: 3.125rem;
  }
`

const StyledCol = styled(Col)`
    @media (max-width: 576px) {
        margin-bottom: 6.25rem;
    }
`;

const StyledRow = styled(Row)`
    padding: 3.75rem 0rem 3.75rem 0rem;
    @media (max-width: 576px) {
        padding: 0rem;
    }
`;

const StyledRowBackground = styled(Row)`
    padding: 3.75rem 3.75rem 3.75rem 3.75rem;
    text-align: center;
    color: #fff;
    background-image: url(${MapBackground});
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 576px) {
        padding: 1rem 1rem 5rem 1rem;
    }
`;

const StyledText = styled.h1`
    font-family: 'Nexa Regular';  
    font-size: 4rem
    @media (max-width: 1000px) {
        font-size: 2.143rem;
    }
`;

export default Services;
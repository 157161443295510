// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// Image grid

import styled from 'styled-components';
import Review1 from '../assets/images/Review1.jpg';
import Review2 from '../assets/images/Review2.jpg';
import Review3 from '../assets/images/Review3.jpg';
import Review4 from '../assets/images/Review4.jpg';
import Review5 from '../assets/images/Review5.jpg';
import Review6 from '../assets/images/Review6.jpg';
import Review7 from '../assets/images/Review7.jpg';
import Review8 from '../assets/images/Review8.jpg';

const Grid = () => {
  return (
    <>
      <StyledDiv>
        <StyledImg src={Review1} />
        <StyledImg src={Review2} />
        <StyledImg src={Review3} />
        <StyledImg src={Review4} />
        <StyledImg src={Review5} />
        <StyledImg src={Review6} />
        <StyledImg src={Review7} />
        <StyledImg src={Review8} />
      </StyledDiv>
    </>
  )
}

const StyledDiv = styled.div`
  margin-bottom: 3.125rem;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-auto-flow: dense;
  @media (max-width: 576px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
`;

const StyledImg = styled.img`
  border-radius: .66rem;
  width: 100%;
  height: auto;
`;

export default Grid;



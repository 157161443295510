// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// Footer

import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { FiFacebook, FiInstagram, FiLinkedin, FiPhone, FiMail } from 'react-icons/fi';
import logo from '../assets/images/logo-solo.png';
import state from '../assets/images/nc-shape.svg';

const Footer = () => {
  return (
    <>
      <StyledFooter fluid={true}>
        <StateShape src={state} alt="shape" />
        <Blank />
        <StyledLogo src={logo} alt="logo" />

        <StyledLink href="/services">Services</StyledLink>
        <StyledLink href="/about">About</StyledLink>
        <StyledLink href="/contact">Contact</StyledLink>

        <ContactContainer>
          <h6><FiPhone />   910-547-7010 </h6>
          <h6><FiMail />   CardinalPrideProperty@gmail.com </h6>

          <FacebookIcon onClick={() => window.open("https://www.facebook.com/Cardinalpridepropertycare/", "_blank")} />
          <InstagramIcon onClick={() => window.open("https://www.instagram.com/cardinalpridepropertycare/", "_blank")} />
          <LinkedinIcon onClick={() => window.open("https://www.linkedin.com/in/logan-furlough-b92106104/", "_blank")} />
        </ContactContainer>

        <h6>Copyright &copy; Cardinal Pride LLC 2022</h6>
      </StyledFooter>
    </>
  )
}

const StyledFooter = styled(Container)`
    background: #b8b8b8;
    border-top: 6px solid #Cab686;
    text-align: center;
    text-decoration: none;
`;

const StyledLogo = styled.img`
    width: 15rem;
    padding: 0.5rem;
`

const StateShape = styled.img`
    width: 20rem;
    height: auto;
    position: relative;
    top: -50px;
`;

const Blank = styled.h1`
    margin: 0;
    padding: 0;
`;

const StyledLink = styled.a`
    display: block;
    text-decoration: none;
    font-size: 1.125rem;
    color: #3e3e3e;
    font-weight: bold;
    &:hover {
        opacity: 0.7;
        color: #3e3e3e;
    }
`;

const ContactContainer = styled(Container)`
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
`;

const baseIconStyles = css`
    background: #a6001d;
    border-radius: 50%; 
    color: #000000;
    overflow: visible;
    width: 2.25rem;
    height: auto;
    padding: 5px;
    margin: 0.5rem;
    &:hover {
        opacity: 0.7;
    }
`

const FacebookIcon = styled(FiFacebook)`
    ${baseIconStyles}
;`

const InstagramIcon = styled(FiInstagram)`
    ${baseIconStyles}
;`

const LinkedinIcon = styled(FiLinkedin)`
    ${baseIconStyles}
;`

export default Footer;

// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// Header

import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import logo from '../assets/images/logo-solo.png';

const Header = ({ text, image, page }) => {

  return (
    <>
      {page === 'home' ? (
        <StyledContainer background={image} fluid>
          <StyledLogo src={logo} alt="logo" />
        </StyledContainer>
      ) : (
        <StyledContainer background={image} fluid>
          <StyledText>{text}</StyledText>
        </StyledContainer>
      )}
    </>
  );
}

const StyledText = styled.h1`
    font-family: 'Nexa Regular';  
    font-size: 6.25rem;  
    color: #4c1b1b;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.2);
    @media (max-width: 600px) {
        font-size: 3.5rem;
    }
`;

const StyledLogo = styled.img`
    width: 50rem;
    @media (max-width: 715px) {
        width: 22rem;
    }
`;

const StyledContainer = styled(Container)`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    background-image: url(${(props) => props.background});
    background-size: cover;
    background-position: center center;
    padding-bottom: 3.75rem;
    height: 60vh;
    min-height: 31rem;
    width:100%;
    border-bottom: 6px solid #cc7988;
    &:before {
        z-index: -1;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: radial-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
        background: -webkit-radial-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
        background: -moz-radial-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
    }
`;

export default Header;

// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// Contact page

import { Container, Row, Col } from 'react-bootstrap';
import Card from '../components/Card.js';
import Button from '../components/Button.js';
import EmailImage from '../assets/images/email.svg';
import PhoneImage from '../assets/images/phone.svg';
import styled, { css } from 'styled-components';
import { FiFacebook, FiInstagram, FiLinkedin } from 'react-icons/fi';

const Contact = () => {
  return (
    <>
      <Container>
        <StyledRow style={{ paddingTop: 150 }}>
          <h1>
            Contact us today. We look forward to working with you.
          </h1>
          <p>
            Be sure to check out our social media for current projects and more of what we do.
          </p>
          <p>
            <FacebookIcon onClick={() => window.open("https://www.facebook.com/Cardinalpridepropertycare/", "_blank")} />
            <InstagramIcon onClick={() => window.open("https://www.instagram.com/cardinalpridepropertycare/", "_blank")} />
            <LinkedinIcon onClick={() => window.open("https://www.linkedin.com/in/logan-furlough-b92106104/", "_blank")} />
          </p>
        </StyledRow>
        <StyledRow>
          <StyledCol sm={6}>
            <Card title={null} image={EmailImage}
              paragraph={"Email us today at CardinalPrideProperty@gmail.com. \n We will get in touch as soon as possible."} />
            <Button onClick={(e) => {
              window.location = "mailto:cardinalprideproperty@gmail.com";
              e.preventDefault();
            }}
              text="Email Today" path="#" type="main" />
          </StyledCol>
          <StyledCol sm={6}>
            <Card title={null} image={PhoneImage}
              paragraph={"Get in touch fast at (910)-547-7010 \n If we are unable to answer please leave a message."} />
            <Button onClick={(e) => {
              window.location = "tel:9105477010";
              e.preventDefault();
            }}
              text="Call Now" path="#" type="main" />
          </StyledCol>
        </StyledRow>
      </Container>
    </>
  );
}

const StyledCol = styled(Col)`
    margin-bottom: 3.125rem;
    @media (max-width: 576px) {
        margin-bottom: 6.25rem;
    }
`;

const StyledRow = styled(Row)`
    padding: 3.75rem 0rem 3.75rem 0rem;
`;

const baseIconStyles = css`
    border: 1px solid black;
    border-radius: 50%; 
    color: #000000;
    overflow: visible;
    width: 3.25rem;
    height: auto;
    padding: 5px;
    margin: 0.5rem;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.2);
    &:hover {
        opacity: 0.7;
        box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.2), 4px 4px 9px rgba(0, 0, 0, 0.2);
    }
`

const FacebookIcon = styled(FiFacebook)`
    ${baseIconStyles}
;`

const InstagramIcon = styled(FiInstagram)`
    ${baseIconStyles}
;`

const LinkedinIcon = styled(FiLinkedin)`
    ${baseIconStyles}
;`

export default Contact;
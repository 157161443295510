// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// Using hooks records the pages y position.  Used for animating navbar

import { useState, useEffect } from 'react';

const ScrollPosition = () => {
  const [bodyOffset, setBodyOffset] = useState(document.body.getBoundingClientRect());
  const [scrollY, setScrollY] = useState(bodyOffset.top);
  const listener = (e) => {
    setBodyOffset(document.body.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
  };
  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });
  return {
    scrollY
  };
}

export default ScrollPosition;
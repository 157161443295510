// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// Card

import styled from 'styled-components';

const Card = ({ image, title, paragraph }) => {

  return (
    <>
      {paragraph != null ? (
        <>
          <StyledImage src={image} />
          <h2>{title}</h2>
          <StyledText>{paragraph}</StyledText>
        </>
      ) : (
        <>
          <StyledImage src={image} />
        </>
      )}
    </>
  );
}

const StyledImage = styled.img`
    width: 25rem;
    margin-bottom: 2rem;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    @media (max-width: 1000px) {
        width: 17rem;
    }
`;

const StyledText = styled.p`
    white-space: pre-wrap; 
    @media (max-width: 1000px) and (min-width: 576px) {
        min-height: 9.375rem;
    }
`;

export default Card;

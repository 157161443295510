// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// Home page

import { Container, Row, Col } from 'react-bootstrap';
import Header from '../components/Header.js';
import Card from '../components/Card.js';
import Button from '../components/Button.js';
import ServicesArt from '../assets/images/services-art.svg';
import SuitArt from '../assets/images/suit-art.svg';
import MapBackground from '../assets/images/map-background-1.svg';
import HeaderImage from '../assets/images/home-header.jpg';
import styled from 'styled-components';
import { FiPhone } from 'react-icons/fi';

const Home = () => {
  return (
    <>
        <Header text="Home Page" image={HeaderImage} page="home" />
        <Container>
          <StyledRow>
            <h1>
              Fully Insured.
              Locally owned and operated.
              Wilmington, NC.
            </h1>
            <p>
              Cardinal Pride Property Care has the homeowner in mind. Our goal is to provide a full handyman service with unmatched service.
              When you choose Cardinal Pride we will take the necessary steps to exceed your expectations and get the job done right the first time.
            </p>
          </StyledRow>
          <StyledRow>
            <StyledCol sm={6}>
              <Card title="Our Services" image={ServicesArt}
                paragraph={"If you can find something that needs fixing we can fix it. \n We have qualified craftsmen for any size project. \n Our experienced staff is ready to discuss your project."} />
              <Button text="Explore Services" path="/services" type="main" />
            </StyledCol>
            <StyledCol sm={6}>
              <Card title="About Us" image={SuitArt}
                paragraph={"We work with you to accomplish your home repair needs. \n With multiple home repairs Cardinal Pride works efficiently. \n We employ only experienced and qualified professionals."} />
              <Button text="Learn More" path="/about" type="main" />
            </StyledCol>
          </StyledRow>
        </Container>
        <Container fluid>
          <StyledRowBackground>
            <h1>
              Let us know what we can help with and let's start a great relationship.
            </h1>
            <StyledText>Contact Today</StyledText>
            <Button text="Click Here" path="/contact" type="secondary" />
            <h1>or</h1>
            <h1><FiPhone />   910-547-7010 </h1>
          </StyledRowBackground>
        </Container>
    </>
  );
}

const StyledCol = styled(Col)`
    margin-bottom: 3.125rem;
    @media (max-width: 576px) {
        margin-bottom: 6.25rem;
    }
`;

const StyledRow = styled(Row)`
    padding: 3.75rem 0rem 3.75rem 0rem;
`;

const StyledRowBackground = styled(Row)`
    padding: 3.75rem 3.75rem 3.75rem 3.75rem;
    text-align: center;
    color: #fff;
    background-image: url(${MapBackground});
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const StyledText = styled.h1`
    font-family: 'Nexa Regular';  
    font-size: 4rem;
    @media (max-width: 1000px) {
        font-size: 2.143rem;
    }
`;

export default Home;
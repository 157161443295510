// Cardinal Pride Property Care   //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// Using bootstrap carousel showcases before and after images

import { Carousel } from 'react-bootstrap';
import styled from 'styled-components';
import HouseBefore from '../assets/images/house-before.jpg';
import HouseAfter from '../assets/images/house-after.jpg';
import DeckBefore from '../assets/images/deck-before.jpg';
import DeckAfter from '../assets/images/deck-after.jpg';
import RailingBefore from '../assets/images/railing-before.jpg';
import RailingAfter from '../assets/images/railing-after.jpg';
import TrailerBefore from '../assets/images/trailer-before.jpg';
import TrailerAfter from '../assets/images/trailer-after.jpg';
let data = [
  [HouseBefore, HouseAfter, "house"],
  [DeckBefore, DeckAfter, "deck"],
  [RailingBefore, RailingAfter, "railing"],
  [TrailerBefore, TrailerAfter]
];


export const Slide = ({ beforeSource, afterSource, alt }) => {
  return (
    <ImageContainer fluid>
      <StyledImage src={beforeSource} alt={alt} />
      <StyledImage src={afterSource} alt={alt} />
      <StyledTextRight>After</StyledTextRight>
      <StyledTextLeft>Before</StyledTextLeft>
    </ImageContainer>
  )
}

const Slides = () => {
  return (
    <>
      <Carousel>
        {data.map((item, index) => {
          return (
            <Carousel.Item key={index}>
              <StyledRow>
                <Slide beforeSource={data[index][0]} afterSource={data[index][1]} alt={data[index][2]} />
              </StyledRow>
            </Carousel.Item>
          )
        })}
      </Carousel>
    </>
  )
}

const StyledImage = styled.img`
    margin: 0 0.5rem 10rem 0.5rem;
    width: 30rem;
    min-width: 300px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.2);
    @media (max-width:  1000px) {
        width: 22.8rem;
        margin: 0 0.5rem 3rem; 0.5rem;
    }
    @media (max-width: 547px) {
        margin: 0 0 1rem 0;
        width: 100%;
    }
`;

const StyledRow = styled.div`
    display: inline-block
`;

const ImageContainer = styled.div`
    position: relative;
    display: inline;
    color: #ffffff;
`;

const StyledTextLeft = styled.h1`
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    @media (max-width: 791px) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @media (max-width: 1000px) {
        font-size: 2.143rem;
    }
    @media (max-width: 547px) {
      display: none;
    }
`;

const StyledTextRight = styled.h1`
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    @media (max-width: 791px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 25rem;
    }
    @media (max-width: 1000px) {
        font-size: 2.143rem;
    }
    @media (max-width: 547px) {
      display: none;
    }
`;

export default Slides; 